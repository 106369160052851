exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jengaclock-index-jsx": () => import("./../../../src/pages/jengaclock/index.jsx" /* webpackChunkName: "component---src-pages-jengaclock-index-jsx" */),
  "component---src-pages-jengaclock-privacy-jsx": () => import("./../../../src/pages/jengaclock/privacy.jsx" /* webpackChunkName: "component---src-pages-jengaclock-privacy-jsx" */),
  "component---src-pages-newshub-index-jsx": () => import("./../../../src/pages/newshub/index.jsx" /* webpackChunkName: "component---src-pages-newshub-index-jsx" */),
  "component---src-pages-newshub-privacy-jsx": () => import("./../../../src/pages/newshub/privacy.jsx" /* webpackChunkName: "component---src-pages-newshub-privacy-jsx" */),
  "component---src-pages-newshub-terms-jsx": () => import("./../../../src/pages/newshub/terms.jsx" /* webpackChunkName: "component---src-pages-newshub-terms-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */)
}

